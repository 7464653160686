@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay/SFProDisplay-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}