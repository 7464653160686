.dateAndTimePicker {
    display: grid;
    grid-template-columns: auto 85px;
}

.dateAndTimePicker .react-datepicker__month-container,
.dateAndTimePicker .react-datepicker__time-container {
    float: none
}
.dateAndTimePicker .react-datepicker__time-container{
    width: auto;
}